<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Login Background Image Change
            <v-spacer></v-spacer>
            <!-- <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->
            <!-- <v-col>
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="600px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="red" v-bind="attrs" v-on="on">
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Background</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-img
                              v-if="imagePreview"
                              :src="imagePreview"
                              width="150px"
                              height="150px"
                              contain
                              class="align-center"
                            /><br />
                            <span>Image width="1806" height="1696" </span>
                            <v-btn outlined @click="$refs.showImage.click()"
                              >Upload Image
                              <v-item-group>
                                <input
                                  v-show="false"
                                  label="Upload Image"
                                  ref="showImage"
                                  id="file"
                                  type="file"
                                  style="display: none"
                                  @change="showImage"
                                />
                              </v-item-group>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        v-if="!flag"
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="uploadImage()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-col> -->
          </v-card-title>
          <div v-if="user">
            <v-layout wrap pt-10 pb-10>
              <v-flex md3 pa-4>
                <v-card style="line-height: 16px" tile flat>
                  <div v-viewer style="display: flex">
                    <img
                      style="width: 100%; object-fit: contain; cursor: pointer"
                      :src="baseURL + user.image"
                      height="300px"
                    />
                  </div>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="#FF2323"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                  <v-layout wrap justify-center px-2>
                    <v-flex pt-5 xl6 lg6 md6 pb-2 sm6 xs6 px-5 justify-center>
                      <v-dialog
                        :retain-focus="false"
                        v-model="dialog"
                        persistent
                        max-width="800px"
                        :key="dialog"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            outlined
                            small
                            block
                            color="green"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Edit
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form v-model="addslider" ref="addcat">
                            <v-card-title>
                              <span class="headline"
                                >Edit Background Image</span
                              >
                            </v-card-title>
                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="12" md="12">
                                    <ImageComp
                                      :singleImage="user.image"
                                      @stepper="winStepper"
                                      :height="'1696'"
                                      :width="'1806'"
                                      :heading="'Upload Login Background Image'"
                                      :componentType="'loginImage'"
                                    />
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="dialogclose()"
                              >
                                Close
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                :disabled="!addslider"
                                @click="uploadImage()"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <br />
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
          <div v-else>
            <v-layout pt-15 justify-center fill-height wrap>
              <v-flex text-center xs12 lg12>
                <span style="font-size: 22px; letter-spacing: 1px">
                  No Data Found...
                  <br />
                  Try adding Data.
                </span>
              </v-flex>
            </v-layout>
          </div>
          <!-- <div class="pt-2">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
            ></v-pagination>
          </div> -->
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      checkbox: true,
      ServerError: false,
      loginImage: null,
      showsnackbar: false,
      msg: null,
      pages: 0,
      link: "",
      widthOfCard: "250px",
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      dialog1: false,
      search: "",
      ratings: [],
      dialog: false,
      editdialog: false,
      user: [],
      editingitem:[],
      title: [],
      addslider: false,
      imgId: "",
      rules: {
        required: (value) =>
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
            value
          ) || "Must be link",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
      titleRule: [(v) => v.length < 50 || "Min 50 characters"],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "loginImage") {
        this.loginImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/loginimage/get",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    uploadImage() {
      let formData = new FormData();
      formData.append("photo", this.loginImage);

      axios({
        method: "POST",
        url: "/home/loginimage/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>